<template>
    <div>
        <div class="pageheader pd-t-25 pd-b-15">
            <div class="pd-t-5 pd-b-5">
                <h1 class="pd-0 mg-0 tx-20 text-overflow">Transaksi Masuk</h1>
            </div>
            <div class="breadcrumb pd-0 mg-0">
                <a class="breadcrumb-item" href="#"><i class="fa fa-check-square-o mg-r-5"></i>Transaksi Masuk</a>
                <a class="breadcrumb-item" >
                    <router-link :to="{ name:'transaksi.hibah_masuk'}">
                    Hibah
                    </router-link>
                </a>
                <span class="breadcrumb-item active"> Form Hibah</span>
            </div>
        </div> 

        <!-- MAIN CONTENT ================================================== -->
        <div class="row justify-content-center">
            <div class="col-sm-6">
                <div class="card">
                    <div class="modal-card">
                        <form @submit.prevent="submitForm" enctype="multipart/form-data">
                        <div class="card-header">
                            <!-- Title -->
                            <h5 class="card-header-title">
                                Form Persediaan
                            </h5>
                            <button type="submit" v-if="userdata.role != 'auditor'" class="col-3 btn btn-primary" @click="submitAktif(true)"><i class="fa fa-save mg-r-5"></i>Simpan</button>
                            <button type="button" v-if="showHideBtn" @click.prevent="showHide(false)" class="ml-2 col-1 btn btn-outline-secondary"><i class="fa fa-angle-double-up"></i></button>
                            <button type="button" v-if="!showHideBtn" @click.prevent="showHide(true)" class="ml-2 col-1 btn btn-secondary"><i class="fa fa-angle-double-down"></i></button>
                        </div>
                        <div v-if="showHideBtn" style="margin-bottom:-30px" class="card-body">
                            <input type="hidden" v-model="form.id">
                            <div v-if="userdata.role == 'administrator'" class="form-group row align-items-center">
                                <label class="col-sm-12">Pilih SKPD<span class="text-danger">*</span></label>
                                <div class="col-sm-12">
                                    <v-select 
                                            :options="listPengguna"
                                            :reduce="(label) => label.code"
                                            label="label"
                                            v-model="form.id_user"
                                            ></v-select>
                                </div>
                                <hr>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-sm-12">No Bukti/BAST<span class="text-danger">*</span></label>
                                <div class="col-sm-12  mb-3">
                                    <input type="text" v-model="form.no_bukti" value="" class="form-control" placeholder="">
                                </div>
                                <!-- ================== -->
                                <label class="col-sm-3">Jenis Pencairan<span class="text-danger">*</span></label>
                                <label class="col-sm-3">Jenis Anggaran<span class="text-danger">*</span></label>
                                <label class="col-sm-6">Upload Lampiran</label>
                                <div class="col-sm-3">
                                    <select v-model="form.pencairan" class="form-control">
                                        <option value="GU">GU</option>
                                        <option value="LS">LS</option>
                                        <option value="TU">TU</option>
                                        <option value="UP">UP</option>
                                    </select>
                                    <small>&nbsp;</small>
                                </div>
                                <div class="col-sm-3">
                                    <select v-model="form.anggaran" class="form-control">
                                        <option value="APBD">APBD</option>
                                        <option value="BTT">BTT</option>
                                        <option value="BLUD">BLUD</option>
                                        <option value="APBN">APBN</option>
                                        <option value="APBN Provinsi">APBN Provinsi</option>
                                        <option value="CSR">CSR</option>
                                    </select>
                                    <small>&nbsp;</small>
                                </div>
                                <div class="col-sm-6">
                                    <input ref="fileInput" @change="uploadFile" type="file" accept="application/pdf, image/x-png,image/gif,image/jpeg" value="" class="form-control" placeholder="">
                                    <small class="text-primary">
                                        <a v-if="form.lampiran" :href="$apiconfig + 'uploads/' + form.lampiran" target="_blank" style="cursor:pointer" class="text-primary">Lihat File Sebelumnya<i class="fa fa-external-link ml-2"></i></a>
                                    &nbsp;
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-sm-6">Tanggal Dokumen<span class="text-danger">*</span></label>
                                <label class="col-sm-6">Tanggal Buku<span class="text-danger">*</span></label>
                                <div v-if="!form.id" class="col-sm-6">
                                    <datepicker  :input-class="'form-control'" required=""
                                                v-model="form.tgl_dokumen" placeholder="dd mm YYYY" :disabledDates="disabledStart"></datepicker>
                                </div>
                                <div v-if="!form.id" class="col-sm-6">
                                    <datepicker  :input-class="'form-control'" required="" :disabledDates="disabledDates"
                                                v-model="form.tgl_buku" placeholder="dd mm YYYY"></datepicker>
                                </div>
                                <div v-if="form.id" class="col-sm-6">
                                    <input disabled v-model="selectedDate.tgl_dokumen"  class="form-control" value="12">
                                </div>
                                <div v-if="form.id" class="col-sm-6">
                                    <input disabled v-model="selectedDate.tgl_dokumen" class="form-control" value="12">
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-sm-12">Pemberi Hibah</label>
                                <div class="col-sm-12">
                                    <input type="text" v-model="form.sumber" value="" class="form-control" placeholder="">
                                </div>
                            </div>
                        </div>
                        <div v-if="!showHideBtn" style="margin-bottom:-50px;margin-top:-10px" class="card-body">
                            <div class="webnots-information webnots-notification-box">Klik <i class="fa fa-angle-double-down"></i> untuk melihat informasi Kwitansi</div>
                        </div>
                        <hr>
                        <div v-if="userdata.parent != 0 && !showForm" style="margin:5px;background-color:#f2f2f2;border-radius:5px"  class="bg-neutral card-body">   
                            <div class="form-group row align-items-center">
                                <div class="col-sm-12">
                                    <button type="submit" @click="showHide(false)" class="col-12 btn btn-primary"><i class="fa fa-plus">&nbsp;&nbsp;Tambah Item</i></button>
                                </div>
                            </div>
                        </div>
                        </form>
                        <form @submit.prevent="submitDetailForm" enctype="multipart/form-data">
                        <div v-if="showForm" style="margin:5px;background-color:#f2f2f2;border-radius:5px"  class="bg-neutral card-body">   
                            <input type="hidden" v-model="formDetail.id_transaksi">
                            <input type="hidden" v-model="formDetail.id">
                            <div class="form-group row align-items-center">
                                <label for="name" class="col-sm-8 col-form-label">Kode Persediaan<span class="text-danger">*</span></label>
                                <label for="name" class="col-sm-4 col-form-label">Satuan<span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                        <v-select 
                                            :options="names"
                                            :reduce="(label) => label.code"
                                            label="label"
                                            v-model="formDetail.id_usulan"
                                            @input="handleSelectionChange(1)"
                                            ></v-select>
                                </div>
                                <div class="col-sm-4">
                                    <v-select 
                                        :options="satuans"
                                        :reduce="(label) => label.code"
                                        label="label"
                                        v-model="formDetail.satuan"
                                        @input="handleSelectionChange(2)"
                                        ></v-select>
                                </div>

                                <label class="mt-2 col-sm-8">Nomor Register</label>
                                <label class="mt-2 col-sm-4">Termasuk PPN ?<span class="text-danger">*</span></label>
                                <div class="col-sm-8">
                                    <input type="text" v-model="formDetail.no_regis" value="" class="form-control" placeholder="">
                                </div>
                                <div class="col-sm-4">
                                    <input type="radio" @click="changePpn(1)" value="1" v-model="formDetail.ppn_status" :checked="formDetail.ppn_status == 1" />&nbsp;Ya
                                    &emsp;
                                    <input type="radio" @click="changePpn(0)" value="0" v-model="formDetail.ppn_status" :checked="formDetail.ppn_status == 0" />&nbsp;Tidak
                                </div>
                            </div>

                            <div style="border:2px white solid;padding:5px">
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-4">Jumlah masuk<span class="text-danger">*</span></label>
                                    <label class="col-sm-4">Harga Beli Satuan<span class="text-danger">*</span></label>
                                    <label class="col-sm-4">Harga Total<span class="text-danger">*</span></label>
                                    <div class="col-sm-4">
                                        <input required="" type="text" v-mask="currencyMaskNon" v-model="formDetail.jumlah" @keyup="calculation()" value="" class="form-control" placeholder="">
                                    </div>
                                    <div class="col-sm-4">
                                        <input required="" type="text" v-mask="currencyMask" v-model="formDetail.harga" @keyup="calculation()" value="" class="form-control" placeholder="">
                                    </div>
                                    <div class="col-sm-4">
                                        <input disabled type="text" v-mask="currencyMask" v-model="formDetail.total" value="" class="form-control" placeholder="">
                                    </div>
                                </div>
                                <div class="form-group row align-items-center">
                                    <label class="col-sm-4"></label>
                                    <label class="col-sm-4">Harga Satuan Asli</label>
                                    <label class="col-sm-4">Harga Total Asli</label>
                                     <div class="col-sm-4"></div>
                                    <div class="col-sm-4">
                                        <input required="" disabled type="text" v-mask="currencyMask" v-model="formDetail.harga_temp" value="" class="form-control" placeholder="">
                                    </div>
                                    <div class="col-sm-4">
                                        <input disabled type="text" v-mask="currencyMask" v-model="formDetail.total_temp" value="" class="form-control" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label for="spesification" class="col-sm-12 col-form-label">Keterangan</label>
                                <div class="col-sm-10">
                                    <textarea style="height:50px" class="form-control" v-model="formDetail.keterangan" name="spesification" id="spesification" ></textarea>
                                </div>
                                <div class="col-sm-2">
                                    <button v-if="formDetail.id_transaksi" type="submit" style="height:50px" class="col-12 btn btn-warning"><i class="fa fa-edit"></i></button>
                                    <button v-if="!formDetail.id_transaksi" type="submit" style="height:50px" class="col-12 btn btn-success"><i class="fa fa-plus"></i></button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                
                <!-- CONTENT -->
                <div class="tab-content mb-3">
                    <div class="tab-pane show active" id="anggota-tab" role="tabpanel">
                        <div class="card">
                            <div class="card-header">
                                <div class="input-group input-group-flush input-group-merge">
                                    <input type="search" class="form-control form-control-prepended search" v-model="keywords" v-on:keyup.enter="getData()" placeholder="Cari Item...">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text">
                                            <span class="fe fe-search"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="m-2 table table-sm table-striped  table-responsive-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:4%">No</th>
                                            <th>Kode Barang</th>
                                            <th>Nama Barang</th>
                                            <th>Satuan</th>
                                            <th>Jumlah</th>
                                            <th>Total</th>
                                            <th style="width:10%">Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody >
                                        <tr v-for="(item, index) in dataItem" :key="index">
                                            <td>{{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}</td>
                                            <td>{{ item.code }}</td>
                                            <td>
                                                <div style="width:200px;white-space: normal;">
                                                {{ item.name }}
                                                </div>
                                            </td>
                                            <td>{{ item.satuan }}</td>
                                            <td>{{ item.jumlah }}</td>
                                            <td>{{ item.total | VMask(currencyMask) }}</td>
                                            <td class="text-center">
                                                <!-- <button v-if="item.sisa != item.jumlah" @click.prevent="alertForm('diedit')" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                <button v-if="item.sisa != item.jumlah" @click.prevent="alertForm('dihapus')" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button> -->
                                                <button v-if="userdata.parent != 0" @click.prevent="editForm(item.id)" class="btn btn-warning btn-sm mr-2"><i class="fe fe-edit"></i></button>
                                                <button v-if="userdata.parent != 0" @click.prevent="postDelete(item.id)" class="btn btn-danger btn-sm mr-2"><i class="fe fe-trash-2"></i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="card-footer border-top-0">
                                <div style="float:right">
                                    <!-- <ul class="pagination mb-0"></ul> -->
                                    <v-pagination v-if="pagination.totalPages > 0" v-model="pagination.currentPage"
                                                :page-count="pagination.totalPages"
                                                :classes="pagination.bootstrapPaginationClasses"
                                                :labels="pagination.paginationAnchorTexts"
                                                v-on:change="getData()"></v-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ADD SPACE -->
            </div>
        </div>
        <br>
        <!-- END MAIN CONTENT -->
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import vPagination from 'vue-plain-pagination'
    import vSelect from "vue-select";
    import "vue-select/dist/vue-select.css";

    import Datepicker from 'vuejs-datepicker';
    import moment from 'moment';

    import Vue from 'vue'
    import VueMask from 'v-mask'
    Vue.use(VueMask);

    import createNumberMask from 'text-mask-addons/dist/createNumberMask';
    const currencyMask = createNumberMask({
        prefix: '',
        allowDecimal: true,
        decimalLimit: 7,
        includeThousandsSeparator: true,
        allowNegative: false,
    });
    const currencyMaskNon = createNumberMask({
        prefix: '',
        allowDecimal: false,
        includeThousandsSeparator: true,
        allowNegative: false,
    });

    moment.locale('id');

    export default {
        metaInfo: {
            title: 'SI',
            titleTemplate: '%s - Data Persediaan'
        },
        data() {
            return {
                baseUrl: this.$apiconfig,
                currencyMask,
                currencyMaskNon,
                dataItem: [],
                names: [],
                satuans: [],
                listPengguna: [],
                fetching: true,
                satuanDefault: 'Satuan',
                showForm: false,
                showHideBtn: true,
                btnSubmit: true,
                statusPpn: false,
                detail : {},
                form: {
                    id: this.$route.params.id ? this.$route.params.id : '',
                    id_user: '',
                    lampiran: '',
                    no_bukti: '',
                    no_dokumen: '',
                    tgl_dokumen: '',
                    tgl_buku: '',
                    sumber: '',
                    status: 'hibah',
                    pencairan: 'GU',
                    anggaran: 'APBD',
                },
                formDetail: {
                    id: this.$route.params.id ? this.$route.params.id : '',
                    id_transaksi: '',
                    id_usulan: '',
                    akun: '',
                    jumlah: '',
                    harga: '',
                    total: '',
                    satuan: '',
                    harga_temp: '',
                    total_temp: '',
                    keterangan: '',
                    ppn: 11,
                    ppn_status: 1,
                    tgl_dokumen: '',
                    no_regis:''
                },
                selectedDate: {
                    tgl_dokumen: '',
                    tgl_buku: '',
                },
                pagination: {         
                    currentPage: 1,
                    totalPages: 0,
                    limit: 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'  
                    },
                    paginationAnchorTexts: {
                        first: '<i class="fe fe-chevrons-left"></i>',
                        prev: '<i class="fe fe-chevron-left"></i>',
                        next: '<i class="fe fe-chevron-right"></i>',
                        last: '<i class="fe fe-chevrons-right"></i>'
                    }
                },
                keywords: '',
                showSelectYear: true,
            }
        },
        computed: {
            ...mapState(['userdata']),
            disabledDates() {
                const startDate = this.userdata.email + '-12-31';
                return {
                    from: new Date(startDate),
                    to: this.form.tgl_dokumen,
                };
            },
            disabledStart() {
                const startDate = this.userdata.email + '-12-31';
                const endDate   = this.userdata.email + '-01-01';

                return {
                    from: new Date(startDate),
                    to: new Date(endDate)
                };
            },
        },
        created() {
            this.$store.dispatch('loadUserData');
            this.loadData();
            this.getData();
            this.getPersediaan();
            this.getSatuan();
            this.getUser();
        },
        components: {
            vPagination,
            Datepicker,
            'v-select' : vSelect,
        },
        methods: {
             loadData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'transaksi/masuk/form', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        idTransaksi: this.form.id,
                    }
                })
                    .then((response) => {
                        if(response.data.data.length > 0){
                            this.form.id = response.data.data[0].id;
                            this.form.id_user = response.data.data[0].id_user;
                            this.form.no_bukti = response.data.data[0].no_bukti;
                            this.form.tgl_dokumen = new Date(response.data.data[0].tgl_dokumen);
                            this.form.tgl_buku = new Date(response.data.data[0].tgl_buku);
                            this.form.lampiran = response.data.data[0].file;
                            this.form.pencairan = response.data.data[0].pencairan;
                            this.form.anggaran = response.data.data[0].anggaran;
                            this.form.sumber = response.data.data[0].sumber;
                            this.selectedDate.tgl_dokumen = response.data.data[0].tgl_dokumen;
                            this.selectedDate.tgl_buku = response.data.data[0].tgl_buku;
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            getData() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'transaksi/masuk/item', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        idTransaksi: this.form.id,
                    }
                })
                    .then((response) => {
                        this.dataItem = response.data.data;
                        this.fetching = false;
                        this.pagination.totalPages = response.data.total_page;
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            submitForm() {
                if(this.form.no_bukti != '' && this.form.tgl_buku != '' && this.form.tgl_dokumen != ''){

                const formData = new FormData();
                formData.append('file', this.form.lampiran);
                formData.append('id', this.form.id);
                formData.append('id_user', this.form.id_user);
                formData.append('no_bukti', this.form.no_bukti);
                formData.append('tgl_buku', this.convertDate(this.form.tgl_buku));
                formData.append('tgl_dokumen', this.convertDate(this.form.tgl_dokumen));
                formData.append('status', this.form.status);
                formData.append('anggaran', this.form.anggaran);
                formData.append('pencairan', this.form.pencairan);
                formData.append('sumber', this.form.sumber);

                this.$http.post(this.baseUrl + 'transaksi/masuk/save', formData)
                .then((response) => {
                    if(this.btnSubmit){
                        this.$swal({
                            icon: 'success',
                            title: "success",
                            text: 'Data berhasil disimpan!',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$router.push('/transaksi/hibah_masuk');
                    }
                    this.showForm = true;
                    this.form.id = response.data.data.id
                    this.formDetail.id = response.data.data.id
                    this.getData();
                })
                .catch(error => {
                    console.log(error.response.data)
                });

                }else{
                     this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Form dengan tanda * wajib diisi!',
                    })
                }
            },
            submitDetailForm() {
                if(this.formDetail.id_usulan != '' && this.formDetail.satuan != ''){

                this.formDetail.tgl_dokumen = this.convertDate(this.form.tgl_dokumen)
                this.$http.post(this.baseUrl + 'transaksi/masuk/save_detail', this.formDetail)
                .then(response => {
                    this.form.id = response.data.data.id
                    this.formDetail.id = response.data.data.id
                    this.formDetail.id_transaksi = '';
                    this.formDetail.id_usulan = '';
                    this.formDetail.akun = '';
                    this.formDetail.jumlah = '';
                    this.formDetail.harga = '';
                    this.formDetail.total = '';
                    this.formDetail.satuan = '';
                    this.formDetail.harga_temp = '';
                    this.formDetail.total_temp = '';
                    this.formDetail.keterangan = '';
                    this.formDetail.ppn = 11;
                    this.formDetail.ppn_status = 1;
                    this.formDetail.no_regis = '';
                    this.getData();
                })
                .catch(error => {
                    console.log(error.response.data)
                });

                }else{
                     this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Form dengan tanda * wajib diisi!',
                    })
                }
            },
            editForm(id) {
                this.showForm = true;
                this.$http.get(this.baseUrl + `transaksi/masuk/edit?id=${id}`)
                    .then((response) => {
                        if(response.data.status){
                            this.formDetail.id_transaksi = response.data.data[0].id;
                            this.formDetail.id_usulan = response.data.data[0].id_usulan;
                            this.formDetail.akun = response.data.data[0].akun;
                            this.formDetail.jumlah = response.data.data[0].jumlah;
                            this.formDetail.harga = response.data.data[0].harga;
                            this.formDetail.satuan = response.data.data[0].satuan;
                            this.formDetail.total = response.data.data[0].total;
                            this.formDetail.harga_temp = response.data.data[0].harga_temp;
                            this.formDetail.total_temp = response.data.data[0].total_temp;
                            this.formDetail.keterangan = response.data.data[0].keterangan;
                            this.formDetail.ppn = response.data.data[0].ppn;
                            this.formDetail.ppn_status = response.data.data[0].ppn_status;
                            this.formDetail.no_regis = response.data.data[0].no_regis;
                        }else{
                            this.$swal({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            })
                        }
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                        this.fetching = false;
                    });
            },
            postDelete(id) {
                var fd = new FormData()
                fd.append('id', id)
                this.$swal({
                    title: 'Apakah kamu yakin?',
                    html: 'Item yang dihapus tidak dapat di kembalikan',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then((result) => {
                    if (result.value) {
                        this.$http({
                            url: this.baseUrl + 'transaksi/masuk/delete',
                            method: 'post',
                            data: fd
                        }).then(() => {
                            this.getData();
                        }).catch(error => {
                            console.log(error.response.data)
                        })
                    }
                });
            },
            getPersediaan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/usulan/select', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                    }
                })
                    .then((response) => {
                        let data = response.data.data;
                        data.forEach((item) => {
                            this.names.push({
                            label: item.persediaan + ' # ' + item.name,
                            code: item.id,
                            status: item.status,
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            getSatuan() {
                this.fetching = true;
                this.$http.get(this.baseUrl + 'admin/master/satuan/select', {
                    params: {
                        page : this.pagination.currentPage,
                        keywords: this.keywords,
                        status: 1,
                    }
                })
                    .then((response) => {
                        let data = response.data.data;
                        data.forEach((item) => {
                            this.satuans.push({
                            label: item.satuan,
                            code: item.id,
                            status: item.status,
                            });
                        });
                    })
                    .catch((error) => {
                        console.log(error.response.data);
                    });
            },
            getUser() {
                this.fetching = true;
                if(this.userdata.role == 'administrator'){
                    this.$http.get(this.baseUrl + 'admin/master/pengguna/select', {
                    params: {
                            page : this.pagination.currentPage,
                            keywords: this.keywords,
                            role: 'dinas',
                        }
                    })
                        .then((response) => {
                            let data = response.data.data;
                            data.forEach((item) => {
                                this.listPengguna.push({
                                label: item.name,
                                code: item.id,
                                });
                            });
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
                }
            },
            calculation(){
                if(this.formDetail.jumlah != '' && this.formDetail.harga != ''){
                    this.formDetail.total = parseFloat(this.formDetail.jumlah.replaceAll(",", "")) * parseFloat(this.formDetail.harga.replaceAll(",", ""));
                    if(this.formDetail.ppn_status == 0){
                        this.formDetail.harga_temp = parseFloat(this.formDetail.harga.replaceAll(",", "")) + (parseFloat(this.formDetail.harga.replaceAll(",", "")) * 11/100);
                        this.formDetail.total_temp =  this.formDetail.harga_temp * parseFloat(this.formDetail.jumlah.replaceAll(",", ""));
                    }else{
                        this.formDetail.harga_temp = parseFloat(this.formDetail.harga.replaceAll(",", ""));
                        this.formDetail.total_temp = this.formDetail.total;
                    }
                }
            },
            convertDate : function (date) {
                return moment(date, 'YYYY-MM-DD').format('yyyy-MM-DD');
            },
            changePpn(key){
                this.formDetail.ppn_status = key;
                this.calculation();
            },
            uploadFile() {
                const file = this.$refs.fileInput.files[0]; 
                var fsize  = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                   this.form.lampiran = this.$refs.fileInput.files[0];
                }
            },
            showHide(key) {
                if(this.form.no_bukti != '' && this.form.tgl_buku != '' && this.form.tgl_dokumen != ''){
                    this.showHideBtn = key;
                    this.btnSubmit   = key;
                }else{
                     this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Form dengan tanda * wajib diisi!',
                    })
                }
            },
            submitAktif(key){
                this.btnSubmit   = key;
            },
            handleSelectionChange: function(key) {
                this.$http.get(this.baseUrl + 'transaksi/masuk/check_item', {
                    params: {
                            id_usulan : this.formDetail.id_usulan,
                            id_satuan : this.formDetail.satuan,
                            tgl_dokumen : this.form.tgl_dokumen,
                            id : this.form.id,
                        }
                    })
                        .then((response) => {
                            if(!response.data.status){
                                this.$swal({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                })
                                if(response.data.message != 'Pastikan kembali, item ini sudah ditambahkan'){
                                    if(key == 1){
                                        this.formDetail.id_usulan = ''
                                    }else{
                                        this.formDetail.satuan = ''
                                    }
                                }
                            }
                        })
                        .catch((error) => {
                            console.log(error.response.data);
                        }); 
            },
            alertForm: function(key) {
                this.$swal({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Tidak dapat '+key+', karena item sudah digunakan!',
                })
            }
        },
    };
</script>
<style>
    /* style sweetalert */
    .swal2-html-container {
        display: block;
        text-align: center;
    }
</style>